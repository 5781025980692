<template>
    <user-dashboard-layout>
        <v-row>
            <v-col cols="12">
                <order-filter
                    :selected="selected"
                    @selected-all="selectedAll"
                    @on-choose-today="onChooseToday"
                    @on-choose-yesterday="onChooseYesterday"
                    @on-choose-weekly="onChooseWeekly"
                    @init-items="init"
                    @print-labels="showPDFPrintLabels"
                    :loading_print_items="loading_print_items"
                ></order-filter>
            </v-col>
        </v-row>
        <v-row class="align-center px-3 mb-3">
            <v-menu
                ref="menu"
                v-model="dataRange"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value="dateRangeText"
                transition="scale-transition"
                offset-y
                style="color: #FFFFFF"
                max-width="290px"
                min-width="290px"
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        class="mx-2"
                        v-model="dateRangeText"
                        :label="$t('general.dates')"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        :rules="[(!ordersDates[0] || !!ordersDates[1]) || $t('general.required-end-date')]"
                    ></v-text-field>
                </template>
                <v-date-picker v-if="dataRange" range v-model="ordersDates" @change="onChooseTime"></v-date-picker>
            </v-menu>
            <v-menu
                ref="menu"
                v-model="timeRange"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value="timeRangeText"
                offset-y
                transition="scale-transition"
                style="color: #FFFFFF"
                max-width="290px"
                min-width="290px"
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        class="mx-2"
                        v-model="timeRangeText"
                        :label="$t('general.times')"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        :rules="[(!ordersTimes[0] || !!ordersTimes[1]) || $t('general.required-end-time')]"
                    ></v-text-field>
                </template>
                <v-row
                    justify="space-around"
                    align="center"
                >
                    <h3>Start:</h3>
                    <v-time-picker
                        v-model="ordersTimes[0]"
                        :max="ordersTimes[1]"
                        format="24hr"
                        @click:hour="onChooseTime"
                        @click:minute="onChooseTime"
                    ></v-time-picker>
                    <h3>End:</h3>
                    <v-time-picker
                        v-model="ordersTimes[1]"
                        :min="ordersTimes[0]"
                        format="24hr"
                        @click:hour="onChooseTime"
                        @click:minute="onChooseTime"
                    ></v-time-picker>
                </v-row>
            </v-menu>
            <v-btn
                color="primary"
                @click="onClearTime"
                class="mr-auto mx-2 elevation-1">
                {{ $t('general.clear') }}
            </v-btn>
        </v-row>
        <v-row class="pl-3 mb-3">
            <v-checkbox @click="pagination.page = 1" type="checkbox" :value="1" v-model="pagination.onlyMachine" :label="$t('general.machine-order')"/>
        </v-row>
        <!--        @pagination="paginate"-->
        <v-data-table
            v-if="orders.data"
            v-model="selected"
            :loading="loading"
            :page.sync="pagination.page"
            :items-per-page.sync="pagination.itemsPerPage"
            :server-items-length="orders.meta.total"
            :headers="filteredHeaders"
            :loading-text="$t('general.loading-please-wait')"
            :items="sortableOrders"
            @pagination="onChooseTime"
            show-select
            class="elevation-1"
            :item-class="fetchClassItem"
        >
            <template v-slot:header="{ props }">
                <thead>
                <tr>
                    <th v-for="header in props.headers" :key="header.text">
                        <span v-if="header.text && !header.sortable">{{
                                $t('general.' + header.text.toLowerCase())
                            }}</span>
                        <a class="header-link" @click="sortingOrders(header.value)"
                           :class="{'header-link--active-sort': sortKey === header.value}"
                           v-else-if="header.text && header.sortable">{{ $t('general.' + header.text.toLowerCase()) }}
                            <v-icon small class="ml-3">
                                {{ sortKey === header.value ? !orderByAsc ? 'mdi-arrow-up' : 'mdi-arrow-down' : '' }}
                            </v-icon>
                        </a>
                        <span v-else>{{ header.key ? header.key : header.text }}</span>
                    </th>
                </tr>
                </thead>
            </template>
            <template v-slot:item.time="{ item }">
                <span style="font-size: 11px;">{{ item.date }}, {{ item.time }}</span>
            </template>
            <template v-slot:item.machine_order="{ item }">
                <span style="font-size: 11px;" v-if="item.delivery_type === 'pick_up_vending'">M</span>
                <span style="font-size: 11px;" v-else>-</span>
            </template>
            <template v-slot:item.status="{ item }">
                <span v-if="item.status">
                    <span v-if="item.status === 'created' || item.status === 'open'" class="primary--text">
                        {{ $t('general.open') }}
                        <span v-if="isNewOrder(item)" class="warning--text">{{ $t('general.new') }}</span>
                    </span>
                    <span v-else-if="item.status === 'paid'" class="success--text">{{ $t('general.paid') }} <span
                        v-if="isNewOrder(item)" class="warning--text">{{ $t('general.new') }}</span></span>
                    <span v-else-if="item.status === 'canceled'" class="warning--text">{{
                            $t('general.canceled')
                        }}</span>
                    <span v-else-if="item.status === 'expired'" class="error--text">{{ $t('general.expired') }} <span
                        v-if="isNewOrder(item)" class="warning--text">{{ $t('general.new') }}</span></span>
                    <span v-else-if="item.status === 'failed'" class="error--text">{{ $t('general.failed') }} <span
                        v-if="isNewOrder(item)" class="warning--text">{{ $t('general.new') }}</span></span>
                    <span v-else class="error--text">{{ $t('general.failed') }} <span v-if="isNewOrder(item)"
                                                                                      class="warning--text">{{
                            $t('general.new')
                        }}</span></span>
                </span>
            </template>
            <template v-slot:item.is_printed="{ item }">
                <span :class="{'success--text': item.is_printed}">{{
                        (item.is_printed) ? $t('general.yes') : $t('general.no')
                    }}</span>
            </template>
            <template v-slot:item.amount="{ item }">
                {{ item.amount }}
            </template>
            <template v-slot:item.created_at="{ item }">
                <span style="font-size: 11px;">{{ item.created_at }}</span>
            </template>
            <template v-slot:top>
                <v-toolbar flat>
                    <v-toolbar-title>{{ $t('general.order') }}</v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <order-show-dialog
                        :order="order"
                        :orderShowDialog="orderShowDialog"
                        :cart="cart"
                        @hideOrderShowDialog="handleHideOrderShowDialog"
                    ></order-show-dialog>
                    <v-row justify="center">
                    </v-row>
                    <v-dialog v-model="dialog" max-width="500px">
                        <v-card>
                            <v-card-title>
                                <span class="text-h5">{{ $t('general.edit-order') }}</span>
                            </v-card-title>

                            <v-card-text>
                                <v-container>
                                    <v-row>
                                        <v-col cols="12">
                                            <v-text-field
                                                v-model="order.name"
                                                :label="$t('general.customer-name')"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-text-field
                                                v-model="order.email"
                                                :label="$t('general.customer-email')"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-text-field
                                                v-model="order.phone"
                                                :label="$t('general.customer-phone')"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-text-field
                                                v-model="order.details"
                                                :label="$t('general.details')"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card-text>

                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="close"
                                >
                                    {{ $t('general.cancel') }}
                                </v-btn>
                                <v-btn color="blue darken-1" text @click="save">
                                    {{ $t('general.save') }}
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                    <v-dialog v-model="vendingModal" max-width="500px">
                        <v-card>
                            <v-card-title class="text-h5">{{ $t('general.dialog_vending_title') }}</v-card-title>
                            <v-card-actions class="d-block">
                                <v-row>
                                    <v-col cols="12 mb-2">
                                        <v-text-field
                                            v-model="vendingPrice"
                                            :label="$t('general.*price')"
                                            :rules="[v => !!v || $t('general.required_price_field'), v => v > 0 || $t('general.invalid_price_number')]"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12 mb-2">
                                        <v-text-field
                                            :loading="vendingLoadCode"
                                            v-model="vendingCode"
                                            :label="$t('general.code')"
                                            :rules="[v => !v || v.length === 5 || $t('general.invalid_code_length')]"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <div class="d-flex justify-space-between mb-3">
                                    <v-btn
                                        @click="cancelVendingModal()"
                                        color="blue darken-1"
                                        text
                                    >{{ $t('general.cancel') }}
                                    </v-btn>
                                    <v-btn
                                        @click="handleVendingModal()"
                                        class="primary"
                                        :disabled="!validateModalVending"
                                    >{{ $t('general.submit') }}
                                    </v-btn>
                                </div>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                    <v-dialog v-model="dialogDelete" max-width="500px">
                        <v-card>
                            <v-card-title class="text-h5">{{ $t('general.are-you-sure-you-want') }}</v-card-title>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="closeDelete"
                                >{{ $t('general.cancel') }}
                                </v-btn>
                                <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="deleteItemConfirm"
                                >{{ $t('general.ok') }}
                                </v-btn>
                                <v-spacer></v-spacer>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-toolbar>
            </template>
            <template v-slot:item.actions="{ item }">
                <v-icon small class="mr-3" @click="showItem(item)">mdi-eye</v-icon>
                <v-icon small class="mr-2"
                        @click="vendingRequest(item)"
                        v-if="vending_enabled"
                        :disabled="loadingVending || Boolean(item['pickup_delivery_vending'])"
                >
                    mdi-storefront
                </v-icon>
                <v-icon small class="mr-2" @click="showPDF(item)">mdi-file-pdf-box</v-icon>
                <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
                <v-icon small class="mr-2" @click="showTemplatesEmail(item)">mdi-email</v-icon>
                <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
            </template>
            <template v-slot:no-data></template>
        </v-data-table>
        <v-dialog v-model="pdfDialog" persistent max-width="600px">
            <v-card>
                <v-card-title>
                    <span class="text-h5">Generate PDF</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-img style="max-height: 400px;" :src="uploadedHeaderUrl"/>
                            <v-col cols="12" sm="6">
                                <v-file-input v-model="uploadedHeader" accept="image/*"
                                              label="Header Image" @change="onImageChange"/>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-select v-model="chosenFormat" :items="availableFormats" label="Format*"
                                          required></v-select>
                            </v-col>
                        </v-row>
                    </v-container>
                    <small>*indicates required field</small>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="pdfDialog = false">
                        Close
                    </v-btn>
                    <v-btn color="blue darken-1" text @click="submitPDF()">
                        Generate
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <transition name="slide-fade" v-if="toast_show_success">
            <div class="toast-success" v-for="pusherMessage in pusherMessages" :key="pusherMessage.message + 1">
                <span aria-hidden="true" class="v-icon notranslate v-alert__icon theme--dark"><svg
                    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" role="img" aria-hidden="true"
                    class="v-icon__svg">
                        <path
                            d="M12 2C6.5 2 2 6.5 2 12S6.5 22 12 22 22 17.5 22 12 17.5 2 12 2M10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z">
                        </path>
                    </svg></span>
                <span class="toast-success__msg">{{ pusherMessage.message }}</span>
            </div>
        </transition>
        <v-dialog v-model="dialogEmailSend" max-width="700px">
            <v-card>
                <v-card-title class="text-h5">{{ $t('general.dialog-email-send-title') }}</v-card-title>
                <v-card-actions class="d-block">
                    <v-row>
                        <v-col cols="12 mb-2">
                            <v-select
                                v-if="templates.length"
                                v-model="template_type"
                                :items="templates.map(template => template.type)"
                                item-text="name"
                                item-value="id"
                                return-object
                                single-line
                                @change="fillTemplateForm(template_type)"
                            ></v-select>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12 mb-2">
                            <v-text-field
                                v-model="email_subject"
                                :label="$t('general.email_subject')"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12 mb-2">
                            <v-card-text class="py-5">
                                <ckeditor
                                    :editor="editor"
                                    v-model="email_text"
                                />
                            </v-card-text>
                        </v-col>
                    </v-row>
                    <div class="d-flex justify-end mb-3">
                        <v-btn @click="onSendEmail" :loading="emailSendLoader" class="primary">
                            {{ $t('general.send-email') }}
                        </v-btn>
                    </div>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <transition name="slide-fade" v-if="email_sending && email_sending_message">
            <div class="toast-success">
                {{ email_sending_message }}
            </div>
        </transition>
        <transition name="slide-fade" v-if="email_sending_fail">
            <div class="toast-error">
                {{ email_sending_message }}
            </div>
        </transition>
    </user-dashboard-layout>
</template>

<script>
import UserDashboardLayout from "../../../layouts/UserDashboardLayout";
import OrderShowDialog from "../../../components/dialogs/OrderShowDialog";
import OrderFilter from "./OrderFilter";
import {mapActions, mapGetters, mapMutations} from "vuex";
import {getEchoInstance} from "@/bootstrap/echo";
import {notifyOrderCreated} from "@/helpers/notification/notification";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import CKEditor from "@ckeditor/ckeditor5-vue2"
import {fetchClassItem} from "@/helpers/dashboard/orders";

export default {
    components: {
        UserDashboardLayout,
        OrderShowDialog,
        OrderFilter,
        ckeditor: CKEditor.component
    },
    data: () => ({
        editor: ClassicEditor,
        currentItemId: 0,
        uploadedHeader: null,
        uploadedHeaderUrl: '',
        chosenFormat: "A4",
        availableFormats: [
            'A4',
            'A5',
            '6,2cm x 10cm'
        ],
        ignoreFormats: [
            '6,2cm x 10cm',
        ],
        pdfDialog: false,
        loadingVending: false,
        dialog: false,
        dialogDelete: false,
        vendingModal: false,
        loading: false,
        sortKey: 'id',
        orderByAsc: false,
        headers: [
            {
                text: "id",
                value: "id",
                sortable: true,
            },
            {
                text: "machine-order",
                value: "machine_order",
                sortable: true,
            },
            {
                text: "Name",
                value: "name"
            },
            {
                text: "Email",
                value: "email"
            },
            {
                text: "Phone",
                value: "phone"
            },
            {
                text: "Details",
                value: "details"
            },
            {
                text: "Time",
                value: "time",
                sortable: true,
            },
            {
                text: "Status",
                value: "status",
            },
            {
                text: "Printed",
                value: "is_printed",
            },
            {
                text: "Amount",
                value: "amount",
            },
            {
                text: "Created",
                value: "created_at",
                sortable: true,
            },
            {
                text: "Actions",
                value: "actions",
            }
        ],
        tasks: [
            {
                id: 1,
                text: 'Collect packets'
            },
            {
                id: 2,
                text: 'Buy bread'
            }
        ],
        pagination: {
            page: 1,
            itemsPerPage: 10,
            onlyMachine: 0,
        },
        editedIndex: -1,
        orderShowDialog: false,
        selectedTasks: [],
        selected: [],
        sortableOrders: [],
        clickChooseButtons: false,
        ordersDates: [],
        ordersTimes: [],
        order_id: null,
        dataRange: false,
        timeRange: false,
        toast_show_success: false,
        dialogEmailSend: false,
        vendingPrice: 0,
        vendingCode: '',
        selectedOrderForModalVending: [],
        validateModalVending: false,
        emailSendLoader: false,
        template_type: null,
        client_email: false,
        email_subject: '',
        email_text: '',
        email_sending: false,
        email_sending_fail: false,
        email_sending_message: '',
        vending_enabled: false,
        pdfLabelOrders: [],
        loading_print_items: false,
        vendingLoadCode: false,
    }),
    computed: {
        ...mapGetters(["defaultOrder", "order", "orders", "cart", "templates", "getShopSettings"]),
        dateRangeText() {
            let dateStart = this.formatDate(this.ordersDates[0]);
            let dateEnd = this.formatDate(this.ordersDates[1]);
            if (dateStart && dateEnd) {
                return dateStart + " ~ " + dateEnd
            }
            return dateStart;
        },
        timeRangeText() {
            let timeStart = this.ordersTimes[0];
            let timeEnd = this.ordersTimes[1];
            if (timeStart && timeEnd) {
                return timeStart + " ~ " + timeEnd;
            }
            return timeStart;
        },
        filteredHeaders() {
            if (this.vending_enabled) {
                return this.headers;
            } else {
                return this.headers.filter(header => header.value !== 'machine_order');
            }
        }
    },
    watch: {
        dialog(val) {
            val || this.close();
        },
        dialogDelete(val) {
            val || this.closeDelete();
        },
        pagination: {
            handler(val) {
                this.loading = true;
                this.setOrders(val).then(orders => {
                    this.loading = false;
                    this.sortableOrders = orders.data;
                    let ids = this.sortableOrders.map((item) => item.id);
                    this.updateViewed({ids: ids});
                });
            },
            deep: true,
        },
        vendingPrice() {
            this.validateModalVending = this.validateVendingForm();
        },
        vendingCode() {
            this.validateModalVending = this.validateVendingForm();
        },
        vendingModal() {
            this.vendingPrice = this.selectedOrderForModalVending["amount"] || 0;
            this.vendingCode = '';
        }
    },
    mounted() {
        this.fetchSettings();
        this.init();
        this.initSocket();
    },

    methods: {
        fetchClassItem,
        ...mapActions(["setOrders", "updateOrder", "destroyOrder", "getCart", "setTemplates", "sendEmail", "getAuthUser", "updateViewed"]),
        ...mapMutations(["setOrder", "showSnackBar"]),

        formatDate(date) {
            if (!date) return null;
            const parts = date.split('-');
            const day = parts[2];
            const month = parts[1];
            const year = parts[0];

            return `${day}-${month}-${year}`;
        },
        parseDutchDate(dateStr) {
            if (!dateStr) {
                return null;
            }
            const parts = dateStr.split('-');
            if (!dateStr || parts.length < 3) {
                return null;
            }
            const day = parseInt(parts[0], 10);
            const month = parseInt(parts[1], 10) - 1; // Month is zero-based
            const year = parseInt(parts[2], 10);

            return new Date(year, month, day);
        },
        async init() {
            this.loading = true;
            await this.setOrders(this.pagination);
            this.sortableOrders = this.orders.data //set orders from backend to template
            this.loading = false;
            await this.setTemplates()
            this.template_type = this.templates.length ? this.templates[0].type : null;
            this.fillTemplateForm(this.template_type);
            this.vending_enabled = this.getShopSettings[0].vending_enabled
            let ids = this.sortableOrders.map((item) => item.id);
            await this.updateViewed({ids: ids});
        },
        // async asyncSortableOrders() {
        //     await this.setOrders(this.pagination);
        //     this.sortableOrders = this.orders.data
        // },
        editItem(item) {
            this.orderShowDialog = false
            this.editedIndex = this.orders.data.findIndex(
                i => i.id === item.id
            );
            this.setOrder(item);
            this.dialog = true;
        },
        deleteItem(item) {
            this.orderShowDialog = false
            this.editedIndex = this.orders.data.findIndex(
                i => i.id === item.id
            );
            this.setOrder(item);
            this.dialogDelete = true;
        },
        async deleteItemConfirm() {
            await this.destroyOrder(this.order);
            await this.init();
            this.closeDelete();
        },
        close() {
            this.dialog = false;
            this.$nextTick(() => {
                this.setOrder(this.defaultOrder);
                this.editedIndex = -1;
            });
        },
        closeDelete() {
            this.dialogDelete = false;
            this.$nextTick(() => {
                this.setOrder(this.defaultOrder);
                this.editedIndex = -1;
            });
        },
        async save() {
            await this.updateOrder(this.order);
            await this.init();
            this.close();
        },
        paginate(pagination) {
            this.pagination = pagination;
            this.setOrders(pagination);
        },
        showPDF(item) {
            this.pdfLabelOrders = [];
            this.availableFormats = this.availableFormats.filter((item) => {
                return !this.ignoreFormats.includes(item);
            });
            this.pdfDialog = true;
            this.currentItemId = item.id;
        },
        showPDFPrintLabels(payload) {
            this.availableFormats.push(...this.ignoreFormats);
            this.pdfDialog = true;
            this.pdfLabelOrders = payload;
        },
        fetchSettings() {
            window.axios.get('/api/pdf/get-settings')
                .then(response => {
                    this.uploadedHeaderUrl = response.data.header;
                    this.chosenFormat = response.data.format;
                    window.axios.get(response.data.header, {responseType: 'blob'})
                        .then(response => {
                            this.uploadedHeader = response.data;
                            // set header name in the file input
                            this.$set(this.uploadedHeader, 'name', 'header');
                        })
                        .catch(error => {
                            console.error('Error fetching header', error)
                        });
                });
        },
        createImage(file) {
            if (!file) {
                return;
            }
            const reader = new FileReader();
            reader.onload = e => {
                this.uploadedHeaderUrl = e.target.result;
            };
            reader.readAsDataURL(file);
        },
        onImageChange(file) {
            this.uploadedHeader = file;
            this.createImage(file);
        },
        handleHideOrderShowDialog() {
            this.orderShowDialog = false;
        },
        submitPDF() {
            if (this.pdfLabelOrders.length) {
                this.submitGroupPdf()
            } else {
                this.submitSinglePdf()
            }
        },
        submitGroupPdf() {
            this.loading_print_items = true;
            let ids = this.pdfLabelOrders.map(function (order) {
                return order.id;
            });
            const formData = new FormData();
            if (this.uploadedHeaderUrl) {
                formData.append('header', this.uploadedHeader);
            }
            formData.append('format', this.chosenFormat);
            formData.append('id', ids);
            window.axios.post('/api/order-print-items', formData).then((res) => {
                this.pdfLabelOrders = []
                if (res.data.status) {
                    if (res.data.path) {
                        ids.forEach((id) => {
                            let item = this.sortableOrders.find(i => i.id === id);
                            if (item) {
                                this.$set(item, 'is_printed', 1);
                            }
                        });
                        window.open(res.data.path, '_blank');
                    }
                } else {
                    alert('error');
                }
            }).catch(() => {
                alert('error');
            }).finally(() => {
                this.loading_print_items = false;
            });
        },
        submitSinglePdf() {
            const formData = new FormData();
            if (this.uploadedHeaderUrl) {
                formData.append('header', this.uploadedHeader);
            }
            formData.append('format', this.chosenFormat);
            formData.append('id', this.currentItemId);
            window.axios.post("/api/pdf/generate-single", formData)
                .then(() => {
                    let client_id = this.$auth.user().id
                    window.open(`/storage/orders/${client_id}/order${this.currentItemId}.pdf`, "_blank")
                })
                .catch(error => {
                    console.error('Error generating PDF', error)
                });
        },
        async showItem(item) {
            this.editedIndex = this.orders.data.findIndex(
                i => i.id === item.id
            );
            this.setOrder(item);
            await this.getCart(item);
            this.orderShowDialog = true
        },
        addTask() {
            this.tasks = this.tasks.map(t => ({...t}))
            this.tasks.push({id: this.tasks.length + 1, text: 'New Task'});

            this.selectedTasks = this.selectedTasks.map(t => {
                return this.tasks.find(e => e.id == t.id) || t;
            })
        },
        selectedAll(checked) {
            checked ? this.selected = this.orders.data : this.selected = []
        },
        async onChooseTime() {
            //frontend time sort, that's why we dont need get-query to backend
            this.clickChooseButtons = false;
            let startDate = this.parseDutchDate(this.formatDate(this.ordersDates[0]));
            let endDate = this.parseDutchDate(this.formatDate(this.ordersDates[1]));
            if (!startDate || !endDate) {
                return null;
            }
            // swap if chosen the other way around
            if (startDate > endDate) {
                [startDate, endDate] = [endDate, startDate];
            }
            this.sortableOrders = this.orders.data.filter(item => {
                const itemDate = this.parseDutchDate(item.date);
                // if date is between startDate and endDate
                if ((!startDate || itemDate >= startDate) && (!endDate || itemDate <= endDate)) {
                    const itemTime = item.time;
                    let startTime = this.ordersTimes[0];
                    let endTime = this.ordersTimes[1];
                    // swap if chosen the other way around
                    if (startTime > endTime) {
                        [startTime, endTime] = [endTime, startTime];
                    }
                    // if time range is not chosen or if time is between startTime and endTime
                    if ((!startTime || !endTime) || itemTime >= startTime && itemTime <= endTime) {
                        return item;
                    }
                }
            })
        },
        async onChooseToday() {
            this.clickChooseButtons = true;
            await this.setOrders(this.pagination);
            this.sortableOrders = this.orders.data.filter(item => {
                if (new Date(item.created_at).toLocaleDateString() === new Date().toLocaleDateString()) {
                    return item;
                }
            })
        },
        async onChooseYesterday() {
            this.clickChooseButtons = true;
            let today = new Date();
            let yesterday = new Date(today.setDate(today.getDate() - 1));
            await this.setOrders(this.pagination);
            this.sortableOrders = this.orders.data.filter(item => {
                if (new Date(item.created_at).toLocaleDateString() === yesterday.toLocaleDateString()) {
                    return item;
                }
            })
        },
        async onChooseWeekly() {
            this.clickChooseButtons = true;
            let today = new Date();
            let weekly = new Date(today.setDate(today.getDate() - 7));
            await this.setOrders(this.pagination);
            this.sortableOrders = this.orders.data.filter(item => {
                if (new Date(item.created_at) >= weekly) {
                    return item;
                }
            })
        },
        onClearTime() {
            this.sortableOrders = [];
            this.clickChooseButtons = false;
            this.ordersDates = [];
            this.ordersTimes = [];
            this.sortableOrders = this.orders.data //set orders from backend to template
        },
        initSocket() {
            getEchoInstance(this.$auth.token()).private(`orders.${this.$auth.user().id}`)
                .listen('OrderCreated', (data) => {
                    this.sortableOrders.unshift(data.order);
                    notifyOrderCreated(this.$t('general.notify_order_created'));
                });
        },
        show_success_toast() {
            this.toast_show_success = true
            setTimeout(() => this.toast_show_success = false, 5000)
        },
        isNewOrder(item) {
            if (new Date(item.created_at).getDate() === new Date().getDate()) {
                return true
            }
        },
        async sortingOrders(val) {
            if (this.sortKey === val) {
                this.orderByAsc = !this.orderByAsc
            }

            this.loading = true
            this.sortKey = val

            await this.setOrders(this.pagination);

            if (!this.orderByAsc) {
                if (this.sortKey === 'id') {
                    this.sortableOrders = this.sortableOrders.sort((a, b) => {
                        return b.id - a.id
                    })
                } else if (this.sortKey === 'time') {
                    this.sortableOrders = this.sortableOrders.sort((a, b) => {
                        return new Date(b.date) - new Date(a.date)
                    })
                } else {
                    this.sortableOrders = this.sortableOrders.sort((a, b) => {
                        return new Date(b.created_at) - new Date(a.created_at)
                    })
                }
            } else {
                if (this.sortKey === 'id') {
                    this.sortableOrders = this.sortableOrders.sort((a, b) => {
                        return a.id - b.id
                    })
                } else if (this.sortKey === 'time') {
                    this.sortableOrders = this.sortableOrders.sort((a, b) => {
                        return new Date(a.date) - new Date(b.date)
                    })
                } else {
                    this.sortableOrders = this.sortableOrders.sort((a, b) => {
                        return new Date(a.created_at) - new Date(b.created_at)
                    })
                }
            }
            this.loading = false
        },
        showTemplatesEmail(item) {
            this.order_id = item.id
            this.client_email = item.email
            this.dialogEmailSend = true
        },
        async getGenerateCode() {
            try {
                this.vendingLoadCode = true;
                const response = await window.axios.get('/api/vending/code/generate')
                this.vendingCode = response.data.code || ''
            } catch (error) {
                this.vendingCode = ''
            } finally {
                this.vendingLoadCode = false
            }
        },
        // method after click to vending icon
        async vendingRequest(item) {
            this.vendingModal = true;
            if (this.loadingVending) {
                return false
            }
            await this.getGenerateCode();
            this.selectedOrderForModalVending = item;
        },
        // method after lick 'send' in modal
        handleVendingModal() {
            if (!this.validateVendingForm()) {
                return false;
            }
            this.loadingVending = true;
            // close modal after click to 'send'
            this.vendingModal = false;
            let payload = {id: this.selectedOrderForModalVending['id'], price: +this.vendingPrice}
            // add vendingCode to data if it is isset
            if (this.vendingCode) {
                payload.code = this.vendingCode.trim()
            }
            window.axios.post("/api/vending", payload)
                .then(response => {
                    if (response.status === 200) {
                        this.$set(this.selectedOrderForModalVending, 'pickup_delivery_vending', response.data)
                        this.$set(this.selectedOrderForModalVending, 'amount', payload.price);
                        this.showSnackBar({
                            color: 'success',
                            timeout: 3000,
                            text: this.$t('general.success-send-to-vending')
                        })
                        // this.vendingPrice = 0;
                        // this.vendingCode = '';
                    } else {
                        this.showSnackBar({
                            color: 'error',
                            timeout: 3000,
                            text: this.$t('general.uncorrect-response-vending')
                        })
                    }
                })
                .catch(() => {
                    this.showSnackBar({color: 'error', timeout: 3000, text: this.$t('general.failed-send-vending')})
                }).finally(() => {
                this.loadingVending = false;
            })
        },
        // method for check validation in send button of modal + watcher
        validateVendingForm() {
            let valid = true;
            // check price validation
            if (!this.vendingPrice || parseFloat(this.vendingPrice) <= 0) {
                valid = false;
            }
            // check code validation
            if (this.vendingCode.trim() !== '' && this.vendingCode.trim().length !== 5) {
                valid = false;
            }

            this.validateModalVending = valid;

            return valid;
        },
        // cancel button of vending modal
        cancelVendingModal() {
            this.vendingModal = false;
            this.vendingPrice = 0;
            this.vendingCode = '';
        },
        onSendEmail() {
            this.emailSendLoader = true
            let obj = {
                type: this.template_type,
                client_email: this.client_email,
                order_id: this.order_id,
                subject: this.email_subject,
                text: this.email_text,
            }
            this.sendEmail(obj)
                .then(data => {
                    this.dialogEmailSend = false
                    this.email_sending = true
                    this.email_sending_message = data.message
                    setTimeout(() => this.email_sending = false, 5000)
                    setTimeout(() => this.email_sending_message = '', 5000)
                })
                .catch((data) => {
                    this.email_sending_fail = true
                    this.email_sending_message = data.message
                    setTimeout(() => this.email_sending_fail = false, 5000)
                    setTimeout(() => this.email_sending_message = '', 5000)
                }).finally(() => {
                this.emailSendLoader = false
            })
        },
        fillTemplateForm(template_type) {
            if (Array.isArray(this.templates)) {
                this.templates.forEach(template => {
                    if (template.type === template_type) {
                        this.email_subject = template.subject
                        this.email_text = template.body
                    }
                })
            }
        },
    }
};
</script>

<style>
thead.v-data-table-header {
    display: none !important;
}

.toast-success {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 270px;
    padding: 20px;
    position: fixed;
    top: 10px;
    right: 5px;
    border: 1px solid #1B5E20;
    border-radius: 5px;
    background: #4caf50;
    z-index: 1000;
    color: #fff !important
}

.toast-error {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 270px;
    padding: 20px;
    position: fixed;
    top: 10px;
    right: 5px;
    border: 1px solid #5e1b1b;
    border-radius: 5px;
    background: #af4c4c;
    z-index: 1000;
    color: #fff !important
}

.toast-success__msg {
    font-size: 16px;
    color: #fff;
}

/* Animation */
.slide-fade-enter-active,
.slide-fade-leave-active {
    transition: all 0.5s;
}

.slide-fade-enter {
    transform: translateX(275px);
    opacity: 0
}

.slide-fade-enter-to {
    transform: translateX(0);
    opacity: 1;
}

.slide-fade-leave {
    transform: translateX(0);
    opacity: 1;
}

.slide-fade-leave-to {
    transform: translateX(275px);
    opacity: 0;
}

.header-link {
    color: rgba(0, 0, 0, .6) !important;
    display: flex;
    align-items: center;
}

.header-link--active-sort {
    color: #4474ee !important;
}
</style>
